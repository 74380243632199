var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Header',{attrs:{"flag":_vm.flag}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"main-two"},[_c('div',{staticClass:"content"},[_vm._m(2),_c('div',{staticClass:"question-title"},[_vm._v(" 您是否也遇到以下问题？ ")]),_c('div',{staticClass:"question-box"},_vm._l((_vm.qList),function(item){return _c('div',{staticClass:"list-item"},[(item.image!=null && item.image !='')?_c('div',{staticClass:"box-icon"},[_c('img',{attrs:{"src":item.image}})]):_vm._e(),_c('div',{staticClass:"box-intro"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('br'),_c('span',{staticClass:"intro"},[_vm._v(_vm._s(item.intro))])])])}),0)])]),_vm._m(3),_c('div',{staticClass:"main-five"},[_c('div',{staticClass:"content"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"function-content"},[_vm._m(6),_c('div',{staticClass:"rg-c"},[_c('el-carousel',{attrs:{"height":"700px","direction":"horizontal","autoplay":true}},_vm._l((_vm.functionImgList),function(item){return _c('el-carousel-item',[_c('img',{attrs:{"data-src":item.image,"alt":item.desc,"src":item.image}})])}),1)],1)])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{staticClass:"video-player",attrs:{"data-src":"../assets/img/liebian_bg01.png","alt":"红包裂变","src":require("../assets/img/liebian_bg01.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-one"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"share-content"},[_c('div',{staticClass:"lf-c"},[_c('div',{staticClass:"share-title"},[_c('div',{staticClass:"share-title-lf"}),_c('div',{staticClass:"share-title-rg"},[_c('span',{staticClass:"share-title-text"},[_vm._v("分享拓客")]),_c('br'),_c('span',[_vm._v("扫码领红包活动")])])]),_c('div',{staticClass:"share-desc"},[_vm._v(" 通过创建活动，分享二维码，客户扫码添加企业微信，领取红包，并可通过分享自己的专属二维码，邀请别人，领取邀请红包。通过以低成本和分享领红包(系统自动秒到账)的激励方式实现客户的快速裂变。将客户引流到自己的私域中，快速锁客。 ")]),_c('div',{staticClass:"wechat-we"},[_c('span',[_vm._v("企业微信与微信有什么区别？")]),_c('a',{staticClass:"wechat-we-link",attrs:{"href":"https://work.weixin.qq.com/","target":"_blank"}},[_vm._v("请点击这里查看 >")])])]),_c('img',{staticClass:"share-red-img",attrs:{"src":require("../assets/image/share-liuc.jpg"),"alt":"分享活动流程图"}})]),_c('div',{staticClass:"share-video-box"},[_c('img',{staticClass:"share-video",attrs:{"data-src":"../assets/img/liebian_01.png","alt":"红包裂变","src":require("../assets/img/liebian_01.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-content"},[_c('div',{staticClass:"lf-c"},[_c('div',{staticClass:"question-desc"},[_vm._v(" 你是否还在为获客难、获客贵、广告投入成本高，苦苦煎熬支撑，赔本赚吆喝吗？ ")])]),_c('img',{staticClass:"qustion-img",attrs:{"src":require("../assets/img/liebian_08.png"),"alt":"问题"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-three"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"product-title"},[_vm._v(" 产品核心优势"),_c('br'),_c('img',{attrs:{"src":require("../assets/img/liebian_02.png")}})]),_c('div',{staticClass:"product-content"},[_c('div',{staticClass:"testBox"},[_c('div',{staticClass:"testCon"},[_c('img',{attrs:{"src":require("../assets/img/liebian_4.png")}}),_c('span',{staticClass:"box-text blue-text"},[_vm._v("快速传播")]),_c('span',{staticClass:"box-desc1"},[_vm._v("裂变红包通过转发微信、朋友圈快速曝光，持续拓客")])]),_c('div',{staticClass:"testCon"},[_c('img',{attrs:{"src":require("../assets/img/liebian_5.png")}}),_c('span',{staticClass:"box-text skyblue-text"},[_vm._v("精准拓客")]),_c('span',{staticClass:"box-desc2"},[_vm._v("根据地理位置、性别筛选客户拓客更精准")])]),_c('div',{staticClass:"testCon"},[_c('img',{attrs:{"src":require("../assets/img/liebian_4.png")}}),_c('span',{staticClass:"box-text blue-text"},[_vm._v("装的更多")]),_c('span',{staticClass:"box-desc1"},[_vm._v("无限制加微信轻松添加100万+好友")])]),_c('div',{staticClass:"testCon"},[_c('img',{attrs:{"src":require("../assets/img/liebian_5.png")}}),_c('span',{staticClass:"box-text skyblue-text"},[_vm._v("触达更准")]),_c('span',{staticClass:"box-desc2"},[_vm._v("企业和个人微信无缝连接,直接微信对话、发起群聊")])]),_c('div',{staticClass:"testCon"},[_c('img',{attrs:{"src":require("../assets/img/liebian_4.png")}}),_c('span',{staticClass:"box-text blue-text"},[_vm._v("成本更低")]),_c('span',{staticClass:"box-desc1"},[_vm._v("让客户主动加你,拓客成本低至3毛")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-title"},[_vm._v("产品功能"),_c('br'),_c('img',{attrs:{"src":require("../assets/img/liebian_02.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"function"},[_c('div',{staticClass:"function-area"},[_c('img',{attrs:{"src":require("../assets/image/fission-share.png")}}),_c('br'),_c('span',[_vm._v("分享海报")])]),_c('div',{staticClass:"function-area"},[_c('img',{attrs:{"src":require("../assets/image/fission-lb.png")}}),_c('br'),_c('span',[_vm._v("红包裂变")])]),_c('div',{staticClass:"function-area"},[_c('img',{attrs:{"src":require("../assets/image/fission-circle.png")}}),_c('br'),_c('span',[_vm._v("朋友圈")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lf-c"},[_c('div',{staticClass:"function-title"},[_vm._v(" 分享拓客 ")]),_c('div',{staticClass:"function-subtitle"},[_vm._v(" 创建活动——分享二维码海报——客户扫码添加企业微信，领取红包——分享自己的专属海报，邀请别人，领取邀请红包 ")]),_c('div',{staticClass:"function-desc"},[_vm._v(" 蓝莓科技的分享拓客是基于企业微信创建拓客活动，分享海报领红包+企业微信快速爆粉，实现简单、实用、效果好、用低成本帮助企业和商家快速、精准拓客，突破普通版微信限制，轻松添加100万+好友，并且基于大数据分析技术，追踪客户路径，标签化管理，可限制地理位置等条件筛选用户，真正帮助企业实现用户引流大和转化率高的一款产品。 ")])])
}]

export { render, staticRenderFns }