<template>
	<div class="container">
		<Header  :flag="flag"/>	
		<div class="banner">
			<!-- <div class="banner-content">
				 <span class="banner-sub-title">AI智能名片-引领智能销售新时代</span>
				 <br/>
				 <span class="banner-sub-title1">AI+微信+小程序，连接微信10亿用户</span>
				 <br/>
				 <div class="banner-qrcode">
					 <img src="../assets/ai_qrcode.jpg" width="150px" height="150px"/>
				 </div>
			 </div> -->
			<!-- <div class="description">
				 “分享拓客”集二维码分享领红包+企业微信快速爆粉 <br/>实现低成本快速引流拓客。
			 </div> -->
			 <!-- <div class="header-pop"></div> -->
			<!-- <video class="video-player" x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true" playsinline poster preload="none" autoplay loop muted>
				 <source  src="https://static.jdip.cn/skypegmwcn-pc/assets/video/bd.mp4" type="video/mp4" x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true" playsinline poster preload="none" autoplay loop>
				 <track kind="captions"></track> 	
			 </video> -->
			 <img data-src="../assets/img/liebian_bg01.png" alt="红包裂变" src="../assets/img/liebian_bg01.png" class="video-player" />
			 
		</div>
		<div class="main-one">
			<div class="content">
				<div class="share-content">
					<div class="lf-c">
						<div class="share-title">
							<div class="share-title-lf"></div>
							<div class="share-title-rg">
								<span class="share-title-text">分享拓客</span><br/><span>扫码领红包活动</span>
							</div>
						</div>
						<div class="share-desc">
							通过创建活动，分享二维码，客户扫码添加企业微信，领取红包，并可通过分享自己的专属二维码，邀请别人，领取邀请红包。通过以低成本和分享领红包(系统自动秒到账)的激励方式实现客户的快速裂变。将客户引流到自己的私域中，快速锁客。
						</div>
						<div class="wechat-we">
							<span>企业微信与微信有什么区别？</span>
							<a href="https://work.weixin.qq.com/" target="_blank"  class="wechat-we-link">请点击这里查看 ></a>
						</div>
					</div>
					<img src="../assets/image/share-liuc.jpg" class="share-red-img" alt="分享活动流程图" />
				</div>
				<div class="share-video-box">
					<!-- <video class="share-video" x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true" playsinline poster="https://static.jdip.cn/skypegmwcn-pc/assets/images/index/video-poster.png" preload="none" autoplay loop muted>
						 <source  src="https://static.jdip.cn/allowance/video/llbSearch.mp4?a=2" type="video/mp4" x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true" playsinline poster preload="none" autoplay loop>
						 <track kind="captions"></track> 	
					 </video> -->
					 <img data-src="../assets/img/liebian_01.png" alt="红包裂变" src="../assets/img/liebian_01.png" class="share-video" />
				</div>
			</div>
		</div>
		<div class="main-two">
			<div class="content">
				<div class="question-content">
					<div class="lf-c">
						<div class="question-desc">
							你是否还在为获客难、获客贵、广告投入成本高，苦苦煎熬支撑，赔本赚吆喝吗？
						</div>
					</div>
					<img src="../assets/img/liebian_08.png" class="qustion-img" alt="问题" />
				</div>
				<div class="question-title">
					您是否也遇到以下问题？
				</div>
				<div class="question-box">
					<div class="list-item" v-for="item in qList" >
						<div class="box-icon" v-if="item.image!=null && item.image !=''">
							<img :src="item.image"/></div>
						<div class="box-intro">
							<span class="title">{{item.title}}</span>
							<br/>
							<span class="intro">{{item.intro}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-three">
			<div class="content">
				<div class="product-title">
					产品核心优势<br/><img src="../assets/img/liebian_02.png" />
					</div>
				<div class="product-content">
					<div class="testBox">
						<div class="testCon">
							<img src="../assets/img/liebian_4.png" />
							<span class="box-text blue-text">快速传播</span>
							<span class="box-desc1">裂变红包通过转发微信、朋友圈快速曝光，持续拓客</span>
						</div>
						<div class="testCon">
							<img src="../assets/img/liebian_5.png" />
							<span class="box-text skyblue-text">精准拓客</span>
							<span class="box-desc2">根据地理位置、性别筛选客户拓客更精准</span>
						</div>
						<div class="testCon">
							<img src="../assets/img/liebian_4.png" />
							<span class="box-text blue-text">装的更多</span>
							<span class="box-desc1">无限制加微信轻松添加100万+好友</span>
						</div>
						<div class="testCon">
							<img src="../assets/img/liebian_5.png" />
							<span class="box-text skyblue-text">触达更准</span>
							<span class="box-desc2">企业和个人微信无缝连接,直接微信对话、发起群聊</span>
						</div>
						<div class="testCon">
							<img src="../assets/img/liebian_4.png" />
							<span class="box-text blue-text">成本更低</span>
							<span class="box-desc1">让客户主动加你,拓客成本低至3毛</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-five">
			<div class="content">
				<div class="product-title">产品功能<br/><img src="../assets/img/liebian_02.png" /></div>
				<div class="function">
					<div class="function-area">
						<img src="../assets/image/fission-share.png"  />
						<br/>
						<span>分享海报</span>
					</div>
					<div class="function-area">
						<img src="../assets/image/fission-lb.png" /><br/>
						<span>红包裂变</span>
					</div>
					<div class="function-area">
						<img src="../assets/image/fission-circle.png" /><br/>
						<span>朋友圈</span>
					</div>
				</div>
				<div class="function-content">
					<!-- <div class="product-title">
						分享拓客<br/><img src="../assets/img/liebian_02.png" />
					</div>
					<div class="function-subtitle">
						创建活动——分享二维码海报——客户扫码添加企业微信，领取红包——分享自己的专属海报，邀请别人，领取邀请红包
					</div>
					<div class="function-images" v-lazy-container="{ selector: 'img' }">
						<div v-for="item in functionImgList" >
							 <img :data-src="item.image" :alt="item.desc" :src="item.image" />
						</div>
					 
					</div>
					<div class="function-desc">
						蓝莓科技的分享拓客是基于企业微信创建拓客活动，分享海报领红包+企业微信快速爆粉，实现简单、实用、效果好、用低成本帮助企业和商家快速、精准拓客，突破普通版微信限制，轻松添加100万+好友，并且基于大数据分析技术，追踪客户路径，标签化管理，可限制地理位置等条件筛选用户，真正帮助企业实现用户引流大和转化率高的一款产品。
					</div> -->
					<div class="lf-c">
						<div class="function-title">
							分享拓客
						</div>
						<div class="function-subtitle">
							创建活动——分享二维码海报——客户扫码添加企业微信，领取红包——分享自己的专属海报，邀请别人，领取邀请红包
						</div>
						<div class="function-desc">
							蓝莓科技的分享拓客是基于企业微信创建拓客活动，分享海报领红包+企业微信快速爆粉，实现简单、实用、效果好、用低成本帮助企业和商家快速、精准拓客，突破普通版微信限制，轻松添加100万+好友，并且基于大数据分析技术，追踪客户路径，标签化管理，可限制地理位置等条件筛选用户，真正帮助企业实现用户引流大和转化率高的一款产品。
						</div>
					</div>
					<div class="rg-c">
						 <el-carousel height="700px"   direction="horizontal" :autoplay="true">
						    <el-carousel-item v-for="item in functionImgList" >
						      <img :data-src="item.image" :alt="item.desc" :src="item.image"  />
						    </el-carousel-item>
						  </el-carousel>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'	
	export default {
		metaInfo: {
			title: '红包裂变',
			meta: [
			  {
				name: 'keywords',
				content: '红包裂变,企业微信发红包,'
			  },
			  {
				name: 'description',
				content: '红包裂变引流帮助您提高影响力，开源节流，节约成本。'
			  }
			]
		},
		name: 'Fission',
		components: {
			Header,
			Footer
		},
		data(){
			return{
				flag:'solution',
				qList:[
					{
						image:require("@/assets/image/fission-salse.png"),
						title:"销售成本高",
						intro:"新客户获取难，投入广告、竞价排名成本越来越高，效果也没达到"
					},
					{
						image:require("@/assets/image/fission-pt.png"),
						title:"受平台限制",
						intro:"大平台霸王条款二选一搞垄断，生存困难",
					},
					{
						image:require("@/assets/image/fission-tool.png"),
						title:"营销工具五花八门",
						intro:"不会运营，大部分营销工具由后台电脑pc端操作复杂、难懂",
					},
					{
						image:require("@/assets/image/fission-income.png"),
						title:"营业额上不去",
						intro:"单一模式，等客户主动上门，自主性差，",
					}
				],
				functionImgList:[
					{desc:"分享海报",image:require("@/assets/image/fission-func1.jpg")},
					{desc:"欢迎语",image:require("@/assets/image/fission-func4.jpg")},
					{desc:"",image:require("@/assets/image/fission-func2.jpg")},
					{desc:"",image:require("@/assets/image/fission-func3.jpg")}
				]
			}	
		}	
	}	
	
</script>

<style scoped lang="less">
	.banner{
		// padding-top: 80px;
		// height: 450px;
		background: #080848;
		height: 100%;
		position: relative;
		.description {
		    position: absolute;
		    width: 100%;
		    left: 0;
		    top: 38.2%;
		    text-align: center;
		    font-size: 42px;
		    color: #ffffff;
		    z-index: 10;
		}
		.header-pop {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.4);
			z-index: 1;
		}
		.video-player {
		    object-fit: cover;
		    width: 100%;
		    height: 100%;
		    display: block;
		}
	}
	.main-one {
		padding: 100px 0 80px 0;
		background: #ffffff;
		.content {
		    width: 1200px;
		    margin: 0 auto;
			.share-content{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.lf-c{
					width: 510px;
					.share-title {
						display: flex;
						align-items: left;
						justify-content:column;
						.share-title-lf{
							width: 6px;
							margin-right: 10px;
							background: #217BFB;
						}
						.share-title-rg{
							font-size: 26px;
							color: #212E4C;
							.share-title-text{
								font-size: 30px;
							}	
						}
					}
					.share-desc{
						padding-top: 40px;
						font-size: 22px;
						color: #61697A;
						line-height: 40px;
						text-indent:50px;
						font-family: "微软雅黑";
					}
					.diandian {
					    width: 30px;
					    height: 6px;
					    display: block;
					    margin: 20px 0 20px 4px;
					}
					.wechat-we{
						font-size: 18px;
						padding-top: 40px;
						color: #61697A;
						.wechat-we-link{
							color: #CCCCCC;
						}
						.wechat-we-link:hover{
							color: #61697A;
						}
					}
				}
				.share-red-img{
					width: 484px;
					height: 300px;
				}
			}
			.share-video-box{
				position: relative;
				.share-video{
					margin-top: 80px;
					display: block;
					width: 1200px;
					// height: 600px;
					// background: #000000;
					border-radius: 40px;
					overflow: hidden;
				}
			}
		}
	}
	.main-two{
		background: #217BFB;
		padding: 40px 0 80px 0;
		.content {
		    width: 1200px;
		    margin: auto;
			.question-content{
				display: flex;
				align-items: center;
				justify-content:column;
				.lf-c{
					width: 710px;
					.question-desc{
						width: 485px;
						// padding-top: 20px;
						margin: auto;
						font-size: 22px;
						color: #F5F6FA;
						line-height: 45px;
						letter-spacing: 5px;
					}
				}	
				// .qustion-img{
				// 	width: 484px;
				// 	height: 300px;
				// }
			}	
			.question-title{
				margin-top: 60px;
				font-size: 36px;
				color: #F5F6FA;
				line-height: 45px;
				letter-spacing: 5px;
				text-align: center;
			}
			.question-box{
				margin-top: 40px;
				height: auto !important;
				display: flex;
				align-items: center;
				justify-content: space-between;
					.list-item{
						height: 360px;
						width: 275px;
						text-align: center;
						background-color: white;
						.box-icon{
							height: 120px;
							width: 270px;
							margin: auto;
							display:table-cell;
							vertical-align: bottom;
							text-align: center;
						}
						.box-intro{
							margin-top: 20px;
							.title{
								font-size: 24px;
								font-weight:600;
								line-height: 65px;
								font-family:"bradley hand itc"
							}
							.intro{
								font-size: 18px;
								line-height:30px;
								display: inline-block;
								width: 220px;
								padding-bottom: 40px;
								text-align: left;
								color: dimgrey;
							}
						}
					}
			}
		}	
	}	
	.main-three{
		background: white;
		.content {
		    width: 1200px;
		    margin: 0px auto;
			.product-title{
				padding-top: 80px;
				text-align: center;
				font-size: 38px;
				font-weight: 600;
				font-family: "行书";
				img{
					padding-right: 20px;
				}
			}
			.product-content{
				margin-top: 40px;
				padding-bottom: 40px;
				.testBox {
				  overflow: hidden;
				  margin: auto;
				  box-sizing: border-box;
				  display: flex;
				  align-items: center;
				  justify-content: space-between;
				  
				  .testCon {
				    position: relative;
				    text-align: center;
				    width: 178px;
				    height: 460px;
					.box-text{
						display: inline-block;
						width: 80px;
						font-size: 34px;
						font-weight: 600;
						z-index: 1000;
					}	
					.box-desc1{
						display: inline-block;
						width: 150px;
						color: white;
						font-size: 18px;
						text-align: left;
						line-height: 35px;
						position: absolute;
						top: 220px;
						left: 20px;
						z-index: 1000;
					}
					.box-desc2{
						display: inline-block;
						width: 150px;
						color: white;
						font-size: 18px;
						text-align: left;
						line-height: 35px;
						position: absolute;
						top: 40px;
						left: 20px;
						z-index: 1000;
					}
				  }
				}
				.blue-text{
					color: #217BFB;
					position: absolute;
					top: 40px;
					left: 45px;
				}
				.skyblue-text{
					color: #22A1FA;
					position: absolute;
					top: 235px;
					left: 50px;
				}
				
				// @keyframes testAni {
				//   0% {
				//      background: #04aef1;
				// 	 border-radius: 50px;
				//   }
				//   50% {
				//     background: #e72365;
				//     border-radius: 50px;
				//     transform: rotate(180deg);
				// 	transform: translate(50px, 50px);
				//   }
				//   100% {
				//     background: #04aef1;
				//     border-radius: 50px;
				//   }
				// }
			}
		}		
	}
	.main-five{
		background: white;
		.content {
		    width: 1200px;
		    margin: 0px auto;
			.product-title{
				text-align: center;
				font-size: 38px;
				font-weight: 600;
				font-family: "行书";
				img{
					padding-right: 20px;
				}
			}
			.function{
				width: 600px;
				margin: 60px auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.function-area{
					text-align: center;
					span{
						display: inline-block;
						font-size: 24px;
					}
				}
			}	
			.function-content{
				// padding-top: 60px;
				// .function-subtitle{
				// 	width: 850px;
				// 	padding-top: 40px;
				// 	margin: auto;
				// 	font-size: 28px;
				// 	color: #61697A;
				// 	line-height: 45px;
				// 	text-align: left;
				// 	padding-bottom: 60px;
				// }
				// .function-images{
				// 	display: flex;
				// 	align-items: center;
				// 	justify-content: space-between;
				// 	img{
				// 		width:290px;
				// 		height:550px; 
				// 	}
				// }
				// .function-desc{
				// 	padding-top: 40px;
				// 	font-size: 28px;
				// 	color: #61697A;
				// 	line-height: 50px;
				// 	letter-spacing: 3px;
				// 	text-align: left;
				// 	padding-bottom: 60px;
				// }
				padding-top: 60px;
				display: flex;
				justify-content: space-between;
				.lf-c{
					width: 610px;
					padding-top: 60px;
					.function-title {
						font-size: 28px;
						color: #212E4C;
					}
					.function-subtitle{
						padding-top: 40px;
						font-size: 22px;
						color: #212E4C;
						line-height: 40px;
					}
					.function-desc{
						padding-top: 40px;
						font-size: 18px;
						color: #61697A;
						line-height: 40px;
					}
					.diandian {
					    width: 30px;
					    height: 6px;
					    display: block;
					    margin: 20px 0 20px 4px;
					}
					.wechat-we{
						font-size: 18px;
						padding-top: 40px;
						color: #61697A;
						.wechat-we-link{
							color: #CCCCCC;
						}
						.wechat-we-link:hover{
							color: #61697A;
						}
					}
				}
				.rg-c{
					width: 389px;
					img{
						width: 300px;
						height: 550px;
					}
				}
			}
		}	
	}	
</style>
